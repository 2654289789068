/* Sweet alert */

html[data-bs-theme=dark] {
    --kt-text-muted: #9D9DA6;
}

[data-kt-app-sidebar-hoverable=true][data-kt-app-sidebar-minimize=on] .app-sidebar:not(.animating) .menuSearchBarInput {
    opacity: 0;
}

[data-kt-app-sidebar-hoverable=true][data-kt-app-sidebar-minimize=on] .app-sidebar:hover:not(.animating) .menuSearchBarInput {
    opacity: 1;
}

label.required:after {
    content: "*";
    color: var(--kt-form-label-color) !important;
}

.btn i.flaticon-search-1{
    padding-right:0!important;
}
.gap-1 {
    gap: 5px;
}

li.breadcrumb-item::after{
    padding-left:0.5rem;
}

ul.p-autocomplete-items{
    margin-bottom: 0!important;
    padding-left: 0!important;
}
html, body { height: 100%; }
body {
    margin: 0;
    font-family: Poppins, Helvetica, "sans-serif" !important;
}

mat-form-field{
    height: 70px !important;
    font-size: 14px !important;
}
mat-label{
    font-size: 14px !important;
}

mat-form-field mat-hint{
    display: flex;
}


.mdc-switch__track::after {
    background-color: rgb(60, 179, 113) !important;
}
.mdc-switch__track::before{
    background-color: #9e9e9e !important;
}
.mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
    background-color: #3878fe !important;
}
.mdc-switch.mdc-switch--unselected:enabled .mdc-switch__handle::after {
    background-color: #fff !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 34px white inset !important;
}

.mdc-data-table__cell, .mdc-data-table__header-cell{
    padding: 0 !important;
}

.amp-btn {
    font-size: 12px;
    background-color: #eee;
    padding: 0 !important;
}
.amp-btn .mdc-button__label {
    display: flex;
}
.amp-btn .mdc-button__label .background-btn-icon {
    width: 36px;
    background-color: #1ecef5;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    color:#fff ;
    align-content: center;
}
.amp-btn .mdc-button__label .background-btn-icon.right {
    width: 35px;
    background-color: #1ecef5;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    color:#fff ;
}
.amp-btn .mdc-button__label .background-btn-icon.danger {
    background-color: #f6635b;
}
.amp-btn .mdc-button__label .background-btn-icon.sky-blue {
    background-color: #26ceff !important;
}
.background-btn-icon.create {
    background-color: #6989E5 !important;
}
.amp-btn .mdc-button__label .btn-text {
    padding: 0 13px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #eeeeee;
    font-size: 14px;
    color: rgb(70, 78, 95);
    font-weight: 100;
    align-content: center;
    height: 36px;
    letter-spacing: 0;
}

.mdc-tab__text-label{
    letter-spacing: normal !important;
}

.amp-btn .mdc-button__label .btn-text.no-radius {
    border-radius: 0;
}
.amp-btn.edit-btn, .amp-btn.add-element-btn {
    padding: 0;
}
.amp-btn.btn-margin-left-15 {
    margin-left: 15px;
}
.background-btn-icon.save {
    background-color: #2AC5B5 !important;
}
.background-btn-icon.d-down {
    background-color: #f7ac01 !important;
}
.amp-btn.btn-margin-right-15 {
    margin-right: 15px;
}
.amp-btn.amp-dropdown i {
    font-size: 13px;
    margin-top: 3px;
}
.amp-btn-dropdown .fas.dropdown, .amp-btn-dropdown .far.dropdown, .amp-btn-dropdown .fa-exclamation-triangle {
    display: flex;
    justify-content: center;
    align-items: center;
}
.amp-btn-dropdown .dropdown-item.publish-li {
    padding-left: 15px !important;
    padding-top: 0;
    padding-bottom: 0;
}


.amp-btn-dropdown .fas.dropdown, .amp-btn-dropdown .fa.fa-pen {
    margin-right: .75em;
    color: #74788d;
}

.amp-btn-dropdown a {
    padding: .7rem 1.2rem;
}

.amp-btn i.fa, .amp-btn i.fas {
    color: #fff;
}

.amp-btn i {
    font-size: 12px;
}

.check-container i {
    font-size: 20px !important;
    color: #0cd19c !important;
}

.amp-btn-group.actions {
    background-color: #f7ac01;
    color: #fff !important;
    font-weight: 100;
    letter-spacing: 0;
    padding: 0px 16.3px;
}
.class-for-menu-actions-amp-table button{
    min-height: 31px;
    height: 31px;
}
.class-for-menu-actions-amp-table button i{
    color: #74788d;
}
.class-for-actions-menu{
    display: contents;
    font-size: 14px !important;
    font-weight: 100 !important;
    letter-spacing: 0 !important;
    color: #74788d !important;
    padding: 0px 1.2rem;
}

.amp-btn-group.url {
    background-color: #0000CC;
    color: #fff !important;
    font-weight: 100;
}

.fa-caret-down{
    color: #fff !important;
}
.mat-column-published .check-container, .mat-column-publishDate .check-container {
    width: 100%;
}

.badge {
    &.daily, &.diario {
        color: whitesmoke;
        background-color: #5e0766;
    }
    &.weekly, &.semanal {
        color: whitesmoke;
        background-color: #01acf7;
    }
    &.monthly, &.mensual {
        color: whitesmoke;
        background-color: #65e74b;
    }
}

.detail-cell__container{
    line-height: normal;
}

/* You can add global styles to this file, and also import other style files */

.margin-left-5 {
    margin-left: 5px !important;
}

.margin-right-5 {
    margin-right: 5px !important;
}
.margin-right-15 {
    margin-right: 15px !important;
}

.margin-top-5 {
    margin-top: 5px !important;
}

.margin-top-15 {
    margin-top: 15px !important;
}

.margin-bottom-5 {
    margin-bottom: 5px !important;
}

.margin-bottom-5 {
    margin-bottom: 5px !important;
}

.margin-bottom-15 {
    margin-bottom: 15px !important;
}

.padding-left-5 {
    padding-left: 5px !important;
}

.padding-right-5 {
    padding-right: 5px !important;
}

.padding-top-5 {
    padding-top: 5px !important;
}

.padding-bottom-5 {
    padding-bottom: 5px !important;
}

.width-percent-5 {
    width: 5%;
}

.width-percent-10 {
    width: 10%;
}

.width-percent-15 {
    width: 15%;
}

.width-percent-20 {
    width: 20%;
}

.width-percent-30 {
    width: 30%;
}

.width-percent-40 {
    width: 40%;
}

.width-percent-50 {
    width: 50%;
}

.width-percent-100 {
    width: 100%;
}

/* Sweet alert */

.swal2-title {
    display: inline-block !important;
}

body.swal2-height-auto {
    height: inherit !important;
}

.swal2-popup.swal2-toast {
    padding: 10px 12px !important;
    /* display: block !important; */
}

.swal2-popup.swal2-toast .swal2-header,
.swal2-popup.swal2-toast .swal2-title,
.swal2-popup.swal2-toast .swal2-content {
    text-align: left !important;
    margin: 0 !important;
}

.swal2-popup.swal2-toast .swal2-title {
    margin-bottom: 4px !important;
}

.swal-custom-popup{
    width: fit-content !important;
    max-width: 400px;
}

.swal2-toast{
    width: fit-content !important;
    max-width: 400px !important;
    padding: 0 !important;
}
.swal2-toast .swal2-html-container {
    padding: 2px !important; /* Aplica el padding deseado */
    margin: 0 !important;
    box-sizing: border-box; /* Asegura que el padding no afecte el ancho */
}
.swal2-toast .swal2-icon {
    padding: 0 !important;
    margin: 0 !important;
}

.swal2-icon.swal2-info::before,
.swal2-icon.swal2-warning::before,
.swal2-icon.swal2-success::before,
.swal2-icon.swal2-error::before {
    content: "";
}


@keyframes glowing {
    0% {
        opacity: 0.3;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.3;
    }
}

.glowing {
    animation: glowing 2s infinite;
}

.root-initial-loader .overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(51, 51, 51, 0.8);
    z-index: 99999 !important;
    width: 100%;
    height: 100%;
}

.root-initial-loader i {
    position: absolute;
    color: rgb(91, 167, 234);
    z-index: 100000 !important;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.root-initial-loader .fa-spin-2x {
    -webkit-animation: fa-spin 1s infinite linear;
    animation: fa-spin 1s infinite linear;
}
ngx-spinner .loading-text{
    font-weight: bold;
    color: white;
    font-size: 1.3em;
}



.awg-container {
    padding: 20px;
}
.awg-layout-add {

    float: right;
}
.cel-custom-elements-list.cdk-drop-list-dragging .cel-list-element:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
/* awg modal*/
.awg-img-file-upload {
    cursor: pointer;
}
/* awg modal*/
/* awg-preview-container */
#awg-preview-container {
    display: block;
    width: 360px;
    height: 640px;
    /* overflow-y: scroll;  */
    background-color: red;
}
/* awg-preview-container */
.custom-file {
    top: -7px;
}
/* CUSTOMIZATION BREADCUM */
.breadcrumb {
    padding: .85rem 1.15rem;
}
.breadcrumb-item.active {
    color: #6c757d;
    font-weight: bold;
}
/* CUSTOMIZATION BREADCUM */
.mr-auto.no-padding {
    padding-right: 0;
    padding-left: 0;
}
/* MATERIAL RE MASTER THEME TO AMP */
.mat-fab.mat-primary, .mat-flat-button.mat-primary, .mat-mini-fab.mat-primary, .mat-flat-button.mat-primary {
    background-color: #0000CC !important;
    color: #fff !important;
    font-weight: 100;
}
.mat-btn-group button,
.mat-btn-group a {
    margin-right: 8px;
}
/* MATERIAL RE MASTER THEME TO AMP */
.cdk-overlay-container {
    z-index: 1100 !important;
}

.swal2-container {
    z-index: 1101 !important;
}
input[type=number]::-webkit-inner-spin-button:not(.notification-filter),
input[type=number]::-webkit-outer-spin-button:not(.notification-filter) {
  -webkit-appearance: none;
  margin: 0;
}
.component-title-link {
    cursor: pointer;
    /* transition: all 0.5s ease; */
}
.component-title-link:hover {
    /* font-weight: bold; */
    color: #0000FF;
    /* transition: all 0.5s ease; */
}

.amp-mat-menu-item > span i{
    font-size: 15px;
    /* margin-right: 10px; */
}

.amp-mat-menu-item > span > span {
    font-size: 13px;
    padding: 0.7rem 1.2rem;
    font-weight: 400;
    color: #212121;
    font-family: Poppins;
    letter-spacing: 0;
}
/* test tablas */
.amp-table-row:hover {
    box-shadow: inset 1px 0 0 #dadce0,
    inset -1px 0 0 #dadce0,
    inset 0 1px 2px 0 rgba(60,64,67,.3),
    inset 0 1px 3px 1px rgba(60,64,67,.15);
}
.table {
    width: 100%;
}
.mat-form-field {
    font-size: 14px;
    width: 100%;
}
.amp-table {
    width: 100%;
}
.amp-table-empty {
    width: 100%;
    text-align: center;
}

.amp-table-empty i {
    font-size: 60px;
}
.amp-table-empty span {
    font-size: 18px;
    display: block;
}
.image-loaded {
    width:100%;
    height: auto;
}
.ul.dropdown-menu li {
    cursor: pointer;
}
/* TABLAAAAAAAAAAAA */
.logo-container {
    width: 40px;
}
mat-cell:first-of-type {
    padding-left: 0px !important;
}
.amp-responsive-item-name {
    display: none;
}
/* .mat-table .mat-cell:last-child, mat-header-cell:last-child  {
    display: none;
} */
.dropdown-item {
    cursor: pointer;
}
.btn-group.dropdown .actions {
    background-color: #f7ac01;
    color: #fff;
    font-weight: 100;
}
.btn-group.dropdown .url {
    background-color: #0000CC;
    color: #fff;
    font-weight: 100;
}
.btn-group.dropdown .dropdown-arrow {
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
}
.amp-btn-dropdown .dropdown-item  {
    padding-left: 0 !important;
}
.amp-btn-dropdown .dropdown-item a i  {
    margin-right: 1.5rem !important;
}

.amp-responsive-item-text i{
    font-size: 16px !important;
    color: #b5b5c3 !important;
}

@media screen and (max-width: 960px) {
    /* .mat-table .mat-cell:first-child, mat-header-cell {
        display: none;
    }
    .mat-table .mat-cell:last-child  {
        display: block;
    } */
    .amp-responsive-item-name {
        width: 50%;
        display: block;
        text-transform: uppercase;
        text-align: left;
    }
    .logo-container {
        width: 50%;
        display: flex;
        justify-content: flex-end;
    }
    .logo-container .image-loaded {
        width: 40px;
    }
    .amp-responsive-item-text {
        /* text-align: right; */
        width: 50%;
        font-weight: 100;
    }
    .mat-table {
      border: 0;
      vertical-align: middle
    }

    .mat-table caption {
      font-size: 1em;
    }


    .mat-table .mat-header-cell {

      border: 10px solid;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    .mat-table .mat-row {
      border-bottom: 5px solid #ddd;
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;
    }
    /*
    .mat-table .mat-row:nth-child(even) {background: #CCC}
    .mat-table .mat-row:nth-child(odd) {background: #FFF}
    */
    .mat-table .mat-cell {
        border-bottom: 1px solid #ddd;
        display: flex;
        font-size: 1em;
        font-weight: bold;
        height: 30px;
        margin-bottom: 0;
        margin-top: 0;
        width: 100%;
    }
    .mat-table .mat-cell:before {
      /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
      content: attr(data-label);
      float: left;
      text-transform: uppercase;
      font-weight: normal;

      font-size: .85em;
    }
    .mat-table .mat-cell:last-child {
      border-bottom: 0;
      padding-right: 0;
    }
    .btn-group.mat-table-resposive {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    .mat-table-resposive button {
        transform: translate(0px, -10%);
    }
    .mat-table .mat-cell:first-child {
      margin-top: 4%;
    }
    .mat-cell {
        overflow: visible;
    }
    .amp-table-filter {
        padding: 0 8px;
    }
}
mat-header-cell:first-of-type {
    padding-left: 0px !important;
}
mat-header-cell {
    font-size: 16px !important;
}
.mat-cell .clickeable {
    cursor: pointer;
}
.mat-cell .clickeable:hover {
    text-decoration: underline;
}
.amp-empty-table {
    text-align: center;
}
.amp-empty-table .fas.fa-table {
    font-size: 8em;
}
.amp-empty-table .empty-text {
    font-size: 2em;
}
/* TABLAAAAAAAAAAAA */
.spinner-container {
    margin: 0 auto;
}
/* single entity container */
.amp-contact-spiner {
    margin: 0 auto;
}
.amp-single-entity-container {
    padding: 0 0 10px;
    width: 100%;
    min-height: 135px;
}
/* .amp-single-entity-container mat-card {
    width: 100%;
} */
.amp-single-entity-container .name {
    font-size: 1.5em;
}
.amp-single-entity-container .amp-card-logo-container {
    display: none;
}
.amp-single-entity-container .amp-card-info-container.info {
    width: 35%;
}
.amp-single-entity-container .amp-card-info-container.auditory {
    width: 62%;
}
.amp-single-entity-container .cards-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
@media screen and (min-width: 960px) {

    .amp-single-entity-container .amp-card-logo-container {
        display: block;
        width: 12%;
    }
    .amp-single-entity-container .amp-card-info-container.info,
    .amp-single-entity-container .amp-card-info-container.auditory {
        width: 43%;
    }
    .amp-card-3{
        width: 32.5%;
    }
}
.amp-card-info-container .amp-card-title {
    font-size: 18px;
}
.amp-card-info-container mat-divider {
    width: 95% !important;
    transform: translateX( -50%);
    left: 50% !important;
}
.amp-card-info-container mat-chip-list {
    display: block;
    float: right;
}
.amp-badge-active {
    background-color: lightgreen !important;
    color: white !important;
    font-weight: 100;
}
.amp-badge-inactive {
    background-color: red !important;
    color: white !important;
    font-weight: 100;
}
.amp-table-input-filter {
    height: 70px;
    width: 100%;
}
.mat-column-logo {
    flex: 0 0 6%;
}
.clear-lookup-btn{
    position: absolute !important;
    right: -4px;
    top: 5px;
}
.form-filters-container{
    display: flex;
    justify-content: space-between;
}
mat-form-field .filter-table-search {
    position: absolute;
    top: 23px;
    right: 3px;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent !important;
}
/*Manipulate background color on hover and focus*/
.mat-mdc-form-field-focus-overlay {
      background-color: transparent !important;
}
entity-comonent-header .mat-flat-button {
    margin-left: 10px;
}
aside.ng-sidebar {
    position: fixed !important;
    z-index: 1000 !important;
}

.mat-expansion-panel-content {
    background-color: #F8F8F8 !important;
}
.mat-expansion-panel-body {
    padding: 20px 24px 16px !important;
}
/* amp btn */
/* BACKGROUND GENERAL */
body {
    background: #14539A;
}
/* BACKGROUND GENERAL */
/* ARROW DOWN */
.arrow-down {
    width: 0;
    height: 0;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-top: 11px solid #fff;
    position: absolute;
    left: 50%;
    transform: translateX(-64%);
}
/* ARROW DOWN */
/* AMP ACCORDERO EXPANTIO PANEL */
mat-expansion-panel {
    border-bottom: 1px solid rgba(0,0,0,.12);
    border-radius: 0;
    box-shadow: none !important;
}
.mat-expansion-panel-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0px 3px 10px -2px rgba(201,185,185,1);
    -moz-box-shadow: 0px 3px 10px -2px rgba(201,185,185,1);
    box-shadow: 0px 3px 10px -2px rgba(201,185,185,1);
}
mat-expansion-panel  .mat-expansion-panel-content {
    background-color: #F8F8F8 !important;
}
.layout-body-container {
    padding-top: 24px;
}
mat-expansion-panel-header {
    border-radius: 0 !important;
}
/* AMP ACCORDERO EXPANTIO PANEL */
/* INPUTS */

button.out-form-btn {
    float: right;
}
/* Border */

/* INPUTS */
.amp-grip {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: move ;
}
.amp-language-icon {
    width: 25px;
    margin-right: 5px;
    cursor: pointer;
}
/* AMP LOGIIIIIIN */
/* AMP LOGIIIIIIN */
.dropdown-menu hr {
    padding: 0;
}

.mat-mdc-menu-item, .mat-mdc-menu-item:visited, .mat-mdc-menu-item:link {
    /* min-height: 30px !important; */
    /* height: 30px !important; */
}

amp-breadcumn {
    display: flex;
}
.link-to-another-component {
    color: rgba(0, 0, 0, .87);
}
.link-to-another-component:hover {
    text-decoration: underline !important;
    color: rgba(0, 0, 0, .87);
}
.modal-body.amp-picture-form {
    padding: 0;
}
ngx-spinner .loading-text{
    font-weight: bold;
    color: white;
    font-size: 1.3em;
}

mat-hint {
    color:#f6635b !important;
    font-weight: 0;
    font-size: 10.5px;
}

.amp-main-badge {
    border-radius: 30px;
    padding: 0 10px;
    color: #fff;
    text-align: center;
    display: inline-block;
    cursor: pointer;
}
.amp-main-badge.draft {
    background-color: #f7ac01;
}
.amp-main-badge.active     {
    background-color: #0cd19c;
}
.amp-main-badge.test {
    background-color: #26ceff;
}
.amp-main-badge.inactive {
    background-color: #f6635b;
}
.amp-main-badge.description {
    background-color: #5a5a5a;
}
.amp-message-icon {
    border-radius: 40px;
    padding: 0 10px;
    background-color: #00f970b5;
    text-align: center;
    display: inline-block;
    cursor: pointer;
}
/* import entity form */
.amp-import-entity .form-group {
    margin-bottom: 0;
    margin-top: 8px;
}
.amp-import-entity .import-button-container {
    display: flex;
}
.amp-import-entity .import-button-container.importing {
    justify-content: flex-end;
    align-items: center;
}
.import-entity-link {
    cursor: pointer;
    text-decoration: underline;
}
.import-spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
/* import entity form */
.not-pointer {
    cursor: no-drop;
}
.unavailable-item {
    color:#ccc !important;
}
.no-link {
    color: #000 !important;
    text-decoration: none;
    font-weight: 100 !important;
}

.no-link.vpp {
    font-weight: bold !important;
    text-decoration: underline !important;
}
.no-link.blu {
    color: #116fbf !important;
}
.no-link:hover {
    cursor: pointer !important;
    text-decoration: underline !important;
}
.cursor {
    cursor: pointer;
}
.cursor:hover {
    text-decoration: underline;
}
.correction-input-chip-full {
    height: 90px !important;
}
.correction-input-chip-full-acquisition-flow {
    min-height: 80px !important;
    height: fit-content !important;
}
.correction-input-chip-void {
    height: 70px !important;
}
mat-paginator mat-form-field{
    height: 41px !important;
}

mat-slide-toggle{
    margin-bottom: 10px;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: #3878fe;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color:mediumseagreen;
}
.amp-hide-table {
    display: none !important;
}
.vendor-view-tabs .nav-tabs {
    margin: 10px 0 0px 0;
    padding: 0px 10px;
}
.dropdown-item.publish-li.activate .fa-times, .dropdown-item.publish-li.activate .fa-check {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: .75rem;
    color: #74788d;
}

.dropdown-item.publish-li.activate .fa-times {
    margin-right: 1rem;

}
.campaigns-options-loading {
    position: absolute;
    right: 185px;
    top: 12px;
}
.campaigns-options-loading.vendor {
    position: absolute;
    right: 185px;
    top: 9px;
}
.campaigns-options-loading.agency {
    position: absolute;
    right: 185px;
    top: 14px;
}

bs-dropdown-container {
    left: -50px !important;
}

.fas {
    margin-top: 3px;
}

.amp-btn-dropdown .fas.dropdown, .amp-btn-dropdown .fa.fa-pen {
    margin-right: .75em;
    color: #74788d;
}

.amp-btn-dropdown a {
    padding: .7rem 1.2rem;
}

.btn-group.dropdown .actions .fa-caret-down:before {
    color: #fff !important;
}

.amp-responsive-item-text {
    font-weight: 100
}

.amp-btn i.fa, .amp-btn i.fas {
    color: #fff;
}



.btn-top-20 {
    margin-top: 20px !important;
}

.amp-card-header {
    padding: 20px !important;
}

.amp-card-header .card-title {
    margin-bottom: 0;
}

.no-padding {
    padding: 0 !important;
}


.card-header {

    font-weight: 500 !important;
    color: #48465b !important;
}

.card-header span {
    font-size: 1.2rem !important;
    font-weight: 500 !important;
    color: #48465b !important;
}
.card-header h3 {
    font-size: 1.2rem !important;
    font-weight: 500 !important;
    color: #48465b !important;
}

.card-header i {
    color: #48465b !important;
}

.amp-btn i {
    font-size: 12px;
}

.amp-top-15 {
    margin-top:15px;
    margin-bottom: 15px;
}

mat-cell  a {
    font-weight: 100 !important;
}

.dropdown-menu > li > a {
    /* padding: 0 1.2rem; */
    padding: .4rem 1.2rem;
    color: #74788d;
}

.dropdown-menu > li > i {
    display: flex;
    justify-content: center;
    align-items: center;
}

.acq-flow-form i {
    color: #fff !important;
}


.tooltip--no-truncate{
    word-break: break-all !important;
    white-space: normal !important;
}
.avatar-content{
    font-size: 12px !important;
}

.dropdown-menu.amp-btn-dropdown {
    font-size: 14px;
}
/* .dropdown-menu:not(.amp-btn-dropdown):not(.dropdown-vendor){
    width: 100%;
} */

/*

    los estilos de abajo, son para poder
    utilizar el alineamiento natural del component bs-dropdown
    de manera correcta sumado a la implementacion del mismo
    adentro de MAT TABLE y SUMADO al echo de los estilos
    que traen los header del template METRONIC, la coexistencia
    LIMPIA de estos tres elementos, es un tema a nivel
    CSS

*/
.amp-c-dropdowm {
    position: absolute !important;
}

.amp-subheader-component{
    background-color: #fff;
    position: relative;
    z-index: 10;
    width: 100%;
    box-shadow: 0 10px 30px 0 rgba(82, 63, 105, .08);
}

.padding-for-view-with-subheader{
    padding-top: 15px;
}

@media (min-width: 992px) {
    .amp-subheader-component{
        background-color: #fff;
        margin-bottom: 15px;
        position: fixed;
        z-index: 10;
        width: calc(100% - var(--bs-app-sidebar-width));
        box-shadow: 0 10px 30px 0 rgba(82, 63, 105, .08);
    }

    .padding-for-view-with-subheader{
        padding-top: calc(var(--kt-toolbar-height) + 10px);
    }
}

/*
    los estilos de arriba, son para poder
    utilizar el alineamiento natural del component bs-dropdown
    de manera correcta sumado a la implementacion del mismo
    adentro de MAT TABLE y SUMADO al echo de los estilos
    que traen los header del template METRONIC, la coexistencia
    LIMPIA de estos tres elementos, es un tema a nivel
    CSS
*/




/* .breadcrumb .breadcrumb-item, .trim-header-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 235px;
}

.trim-header-title {

    max-width: 300px;
} */
.text-white{
    color: #fff !important;
}

mat-paginator {
    color: rgba(0,0,0,.54) !important;
    mat-select {
        padding: 0 10px !important;
    }
}

.border-fullradius-amp {
    border-radius: 50%;
}


.info-container.trim-header-title p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 235px;
}


.info-container.trim-header-title p {
    max-width: 260px;
}
.hide-price-point {
    display:none;
}

.message-content-trim {
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.no-form-table-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.no-form-table-container .panel-head-combo {
   position: relative !important;
}

.no-form-table-container .card-body {
    width: 100%;
}

.no-form-table-container .no-form-table {
    width: 100%;
}

.mat-column-id {
    flex: 0 0 7%;
}

.link-to-another-component {
    cursor: pointer;
}

.pointer {
    cursor: pointer;
}


.add-item-btn {
    margin-right: -11px;
    border-radius: 0 !important;
}

.underline:hover {
    text-decoration: underline;
}


.avatar-loader-fullscreen {
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
}

.avatar-svg-container--sized {
    width: 8vw;
    height: 8vw;
    min-width: 100px;
    min-height: 100px;
}

.avatar-svg-container {
    background-color: antiquewhite;
    border-radius: 100%;
    display: flex;
    margin: auto;
    animation: pulse 1s infinite;
}
.avatar-svg-container svg {
    height: 70%;
    width: 70%;
    margin: auto;
}

@keyframes spin {
    70% {
        transform: rotate(-365deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
  }

  .text-line-through {
      text-decoration: line-through;
  }

  .detail-cell{
    line-height: normal;
    gap: .2rem;
    margin: .4rem 0;
}

.subheader {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 54px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .subheader .subheader-separator {
      display: block;
      width: 12px;
      height: 4px;
      border-radius: 2px; }
      .subheader .subheader-separator.subheader-separator-ver {
        width: 1px;
        height: 22px; }
    .subheader.subheader-solid {
      border-top: 1px solid #ECF0F3;
      background-color: #ffffff; }

  @media (min-width: 992px) {
    .header-fixed.subheader-fixed .subheader {
      position: fixed;
      height: 54px;
      top: 65px;
      left: 0;
      right: 0;
      -webkit-transition: top 0.3s ease;
      transition: top 0.3s ease;
      z-index: 95;
      -webkit-box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.08);
      box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.08);
      background-color: #ffffff;
      border-top: 1px solid #ECF0F3;
      margin: 0; }
    .header-fixed.header-minimize.subheader-fixed .subheader {
      top: 65px;
      -webkit-transition: top 0.3s ease;
      transition: top 0.3s ease; }
    .aside-enabled.subheader-fixed .subheader {
      left: 265px; }
    .subheader-fixed.aside-minimize-hover .subheader,
    .subheader-fixed.aside-minimize .subheader {
      left: 70px; }
    .subheader-enabled:not(.subheader-fixed) .content {
      padding-top: 0; }
    .subheader-enabled:not(.subheader-fixed) .subheader.subheader-solid {
      margin-bottom: 25px; } }

  @media (max-width: 991.98px) {
    .subheader-enabled .content {
      padding-top: 0; }
    .subheader-enabled .subheader.subheader-solid {
      margin-bottom: 15px; } }


.detail-cell__date {
    margin-bottom: -5px;
}


.mat-mdc-tab-body-content{
    overflow: hidden !important;
}

.bg-warning-o-40 {
    background-color: rgba(255, 168, 0, 0.16) !important;
  }

.mdc-text-field {
    padding: 0 !important;
}
