quick-theme-selection,
language-switch-dropdown,
header-notifications,
chat-toggle-button,
user-menu {
    display: flex;
}

.unread-notification-count {
    position: absolute;
    top: 5px;
    left: 23px;
}

.unread-chat-message-count {
    position: absolute;
    top: -6px;
    left: 13px;
}

p-autoComplete {
    padding: 10px;
}

.menuSearchBarContainer {
    width: calc(100% - 20px);
}

.mobile-subscription-warning {
    background-color: #fff;
}

#kt_wrapper>ng-component {
    display: flex;
    flex: 1;
    flex-direction: column;
}

default-layout {
    display: flex;
    flex: 1;
    flex-direction: column;
}

body[data-kt-app-layout=dark-sidebar] .menuSearchBarContainer .p-inputtext {
    background-color: #12121e !important;
    border: 1px solid #2c2d44 !important;
    color: #9899ac !important;
}

body[data-kt-app-layout=dark-sidebar] .menuSearchBarContainer .p-autocomplete-panel {
    background-color: #12121e !important;
    border: 1px solid #2c2d44 !important;
    color: #9899ac !important;
}

body[data-kt-app-layout=dark-sidebar] .menuSearchBarContainer li {
    color: #9899ac !important;
}

body[data-kt-app-layout=dark-sidebar] .menuSearchBarContainer li:hover {
    color: #fff !important;
    background-color: rgba(255, 255, 255, 0.04) !important;
}

body[data-kt-app-toolbar-fixed=true] .app-sidebar-logo {
    height: 71px;
}

[data-kt-app-header-fixed=true][data-kt-app-toolbar-fixed=true] .app-wrapper {
    margin-top: 120;
}
.app-content{
    padding-bottom: 0;
}